.exploreContainer{
  background-color: #fff;
  border-radius: .5rem .5rem 0 0;
  padding-block: 2rem;
  padding-inline: 3rem;
  margin-top: 2rem;
}

h2{
  font-size: clamp(1.25rem, 3vw, 1.5rem) !important;
  margin-bottom: 1rem;
}

h3{
  font-size: clamp(1rem, 3vw, 1.25rem) !important;
  white-space: nowrap;
  margin-bottom: 0;
}

.thing-todo{
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  margin-bottom: .5rem;
}

.services_list{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  /* gap: 1rem; */
}

.services_item > a{
  white-space: nowrap;
  cursor: pointer;
  font-size: .75rem;
  color: var(--clr-primary-200);
  transition: color 250ms ease-in;
}

.services_item > a:hover{
  color: var(--clr-primary-300);
}
.services_item:not(:last-child){
  border-right: 1px solid var(--clr-primary-100);
  
}

.services_item{
  padding-inline: .5rem;
  margin-bottom: .5rem;
}

@media (max-width: 768px){
  .thing-todo{
    flex-direction: column;
    gap: .5rem;
    /* margin-bottom: 1rem; */
  }
}