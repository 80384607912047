.citySection{
  margin-block: 1rem;
}

.cityTitle{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: clamp(1.5rem, 3vw + .1rem, 3rem);
  margin-block: 1rem;
}

h4{
  font-size: clamp(1.125rem, 3vw, 1.5rem) !important;
  font-weight: 700;
  margin-bottom: 0;
}