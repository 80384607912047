.card {
  border-radius: 1rem;
  position: relative;
  isolation: isolate;
  transition: all 250ms ease-in;
  overflow: hidden;
  width: 100%;
  height: 320px;
  cursor: pointer;
}

.card:focus,
.card:focus-within,
.card:focus-visible,
.card:hover {
  box-shadow: var(--box-shadow-heavy);
  -webkit-box-shadow: var(--box-shadow-heavy);
  -moz-box-shadow: var(--box-shadow-heavy);
}

.card:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  transition: opacity 0.35s ease-in-out;
  display: none;
  z-index: 2;
}

.card:focus.card:before,
.card:focus-within.card:before,
.card:focus-visible.card:before,
.card:hover.card:before {
  display: block;
}

.cardImage {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  z-index: 1;
}


.card:focus .cardTitle,
.card:focus-within .cardTitle,
.card:focus-visible .cardTitle,
.card:hover .cardTitle {
  transform: translateY(-1rem);
  opacity: 1;
}

.cardTitle {
  position: absolute;
  bottom: 1.5rem;
  left: 0;
  right: 0;
  z-index: 3;
  text-align: center;
  opacity: 0;
  transition: transform 250ms ease-in;
  color: var(--clr-neutral-000);
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  margin-bottom: 1rem;
}

