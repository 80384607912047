.footer {
  background-color: var(--clr-primary-000);
  padding-block: 2rem;
}

.footerContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.logoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 350px;
}

.logoContainer > img {
  max-width: 350px;
  width: 100%;
}
.socialList {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-bottom: 0;
  padding-left: 0;
}

.socialItem {
  width: 2.5rem;
  height: 2.5rem;
}

.socialLink {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--clr-primary-200);
  padding: 0.5rem;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  background-color: transparent;
  color: var(--clr-primary-200);
  transition: all 250ms ease-in;
}

.socialLink:hover,
.socialLink:active {
  background-color: var(--clr-primary-200);
  color: var(--clr-neutral-100);
}


@media (max-width: 425px){

  .footer{
    padding-bottom: 4rem;
  }
  .logoContainer{
    margin-left: 1rem;
  }

  .logoContainer > img {
    max-width: 300px;
  }

  .socialItem{
    width: 3rem;
    height: 3rem;
  }
}