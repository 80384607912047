.about-page-title{
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: clamp(1.5rem, 3vw + .1rem, 3rem);
  margin-block: 1rem;
}

.imageContainer {
  position: relative;
  isolation: isolate;
  width: 100%;
  min-height: 460px;
}
.decorImages {
  position: absolute;
  width: 325px;
  display: block;
  border-radius: 1rem;
}

.decorImages1 {
  top: 0px;
  right: 0px;
  z-index: -2;
}
.decorImages2 {
  top: 135px;
  right: 20%;
  left: 20%;
  z-index: -1;
}
.decorImages3 {
  top: 240px;
  left: 20px;
  z-index: 0;
}

.decorImages1:hover,
.decorImages2:hover,
.decorImages3:hover{
  z-index: 2;
}

.why-section{
  padding-block: 2rem;
  background-color: #fff;
}

.why-section p {
  max-width: 75ch;
}

.why-section p > span{
  font-weight: 700;
}
@media (max-width: 991px) {
  .imageContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    margin-bottom: 1rem;
  }
  .decorImages {
    position: relative;
    width: 100%;
  }
  .decorImages1 {
    top: 0;
    left: 0;
  }
  .decorImages2 {
    top: 0px;
    left: 0px;
    z-index: 0;
  }

  .decorImages3 {
    top: 0;
    left: 0;
    /* bottom: 0; */
  }

}