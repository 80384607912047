.service-section{
  margin-block: 1rem;
}
.carouselImage{
  display: block;
  width: 100%;
  height: 400px;
  border-radius: .5rem .5rem 0 0 ;
  object-fit: cover;
}

.cardShape{
  background-color: #fff;
  padding: 1rem;
  padding-block: 2rem;
  box-shadow: var(--box-shadow-light);
  -webkit-box-shadow: var(--box-shadow-light);
  -moz-box-shadow: var(--box-shadow-light);
  border-radius:0.5rem;
}

.titleContainer{
  border-radius: 0 0 .5rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: wrap;
}

.serviceTitle{
  font-size: clamp(1.25rem, 3vw, 1.75rem);
}


.service-facilities{
  
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
}

.cardHeader{
  color: var(--clr-primary-200);
  font-size: clamp(1.125rem, 3vw, 1.5rem);
  margin-bottom: 1rem;
}

.informations-list,
.inclusion-list,
.highlights-list{
  display: flex;
  flex-direction: column;
  gap: .5rem;
}

.information-item,
.inclusion-item,
.highlight-item{
  position: relative;
  padding-left: 1rem;
}

.information-item::before,
.inclusion-item::before,
.highlight-item::before{
  content: "";
  position: absolute;
  width: .4rem;
  height: .4rem;
  border-radius: 50%;
  background-color: var(--clr-primary-200);
  left: 3px;
  top: 8px;
}