.section {
  margin-block: 1.5rem;
}

.contact-container {
  display: flex;
  align-items: center;
  gap: 2rem;
}

.contact-us-info {
  background-color: var(--clr-primary-200);
  padding-inline: 2rem;
  padding-block: 3rem;
  border-radius: 1rem;
  color: var(--clr-neutral-000);
}

.contact-us-info > * {
  color: var(--clr-neutral-000);
}
.contact-us-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.contact-links-container{
  display: flex;
  align-items: center;
  gap: 1rem;

}

.contact-links-container >p {
  margin-bottom: 0;
}
.contact-link{
  color: var(--clr-neutral-000);
  cursor: pointer;
}