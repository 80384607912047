.whatsappButton {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.whatsappLink {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 1rem;
  background-color: #36a856;
  background-color: #0dc143;
  border-radius: 100vh;
  color: var(--clr-neutral-000);
  transition: all 250ms ease-in;
}

.whatsappLink:hover {
  box-shadow: -3px 3px 16px -3px rgba(0, 0, 0, 0.72);
  -webkit-box-shadow: -3px 3px 16px -3px rgba(0, 0, 0, 0.72);
  -moz-box-shadow: -3px 3px 16px -3px rgba(0, 0, 0, 0.72);
}
