*,
*::after,
*::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --clr-neutral-000: #fff;
  --clr-neutral-100: #e6e6e6;
  --clr-neutral-200: #cccccc;
  --clr-neutral-300: #b3b3b3;
  --clr-neutral-400: #999999;
  --clr-neutral-500: #808080;
  --clr-neutral-600: #666666;
  --clr-neutral-700: #4c4c4c;
  --clr-neutral-800: #333333;
  --clr-neutral-900: #191919;


  --clr-primary-000: #b7e5f5;
  --clr-primary-100: #56c2e7;
  --clr-primary-200: #0ea8dc;
  --clr-primary-300: #086584;

  --clr-secondary-000: #587795;
  --clr-secondary-100: #113c67;
  --clr-secondary-200: #0e3052;
  --clr-secondary-300: #091e34;

  --clr-accent-000: #6db28c;
  --clr-accent-100: #0c7e40;
  --clr-accent-200: #0a6533;
  --clr-accent-300: #063f20;

  --clr-body-background: #f9f9f9;

  --box-shadow-light: 0px 2px 13px -3px rgba(0,0,0,0.1);
  --box-shadow-heavy: -3px 3px 16px -3px rgba(0, 0, 0, 0.72);
}

html,
body {
  font-size: 1rem !important;
  font-family: "Roboto", sans-serif !important;
  color: var(--clr-neutral-900);
  background-color: var(--clr-body-background) !important;
}

.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
main {
  flex: 1;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: var(--clr-primary-200);
  -webkit-border-radius: 6px;
  border-radius: 6px;
}

/* bootstrap resets */
@media (max-width: 991px){
  .offcanvas{
    max-width: 15rem !important;
  }
}

.accordion-button:not(.collapsed){
  background-color: #f2f2f2 !important;
}

.form-select,
.form-control {
  border: 1px solid var(--clr-primary-200);
}
.form-control,
.btn,
.form-select {
  border-radius: 0.5rem;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}

.form-check-input,
.form-control {
  border: 1px solid var(--clr-primary-200);
}
.btn-primary:focus-visible {
  box-shadow: 0 0 0 0.25rem rgba(86, 194, 231, 0.5);
}

.form-select:focus,
.form-control:focus {
  border-color: var(--clr-primary-200);
  box-shadow: 0 0 0 0.25rem rgba(86, 194, 231, 0.25);
}

.btn {
  padding-inline: 1rem !important;
  line-height: 1.6 !important;
  border-radius: .65rem !important;
}



.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: var(--clr-primary-200);
  --bs-btn-border-color: var(--clr-primary-200);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: var(--clr-primary-300);
  --bs-btn-hover-border-color: var(--clr-primary-300);
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: var(--clr-primary-300);
  --bs-btn-active-border-color: var(--clr-primary-300);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: var(--clr-primary-200);
  --bs-btn-disabled-border-color: var(--clr-primary-200);
}



.btn-outline-primary {
  --bs-btn-color: var(--clr-primary-200);
  --bs-btn-border-color: var(--clr-primary-200);
  --bs-btn-hover-color: var(--clr-neutral-100);
  --bs-btn-hover-bg: var(--clr-primary-300);
  --bs-btn-hover-border-color: var(--clr-primary-200);
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}
.btn-outline-secondary {
  --bs-btn-color: var(--clr-neutral-700);
  --bs-btn-border-color: var(--clr-neutral-300);
  --bs-btn-hover-color: var(--clr-neutral-700);
  --bs-btn-hover-bg: var(--clr-neutral-300);
  --bs-btn-hover-border-color: var(--clr-neutral-400);
  --bs-btn-focus-shadow-rgb: 108, 117, 125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #6c757d;
  --bs-btn-active-border-color: #6c757d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6c757d;
  --bs-gradient: none;
}

/* .btn:hover, */
.btn-outline-secondary:hover,
.btn-outline-secondary:active {
  color: var(--clr-neutral-100) !important;
}

.navbar-nav{
  width: fit-content;
  gap: .5rem;
}

.offcanvas.show .navbar-nav{
  gap: 0.25rem;
}

.offcanvas.show .offcanvas-body > a{
  margin-top: 1rem;
}

.offcanvas-body > a {
  line-height: 1.9 !important;
}
.nav-link{
  font-weight: 500 !important;
  font-size: 1.125rem !important;
  color: var(--clr-primary-200) !important;
  transition: all 250ms ease-in;
}

.nav-link:hover{
  color: var(--clr-primary-100) !important;
}

.nav-link.active{
  color: var(--clr-primary-200) !important;
  border-bottom: 2px solid var(--clr-primary-200);
}
.carousel-control-next{
  justify-content: end !important;
  margin-right: 1rem;
} 
.carousel-control-prev{
  justify-content: start !important;
  margin-left: 1rem;
}

.carousel-indicators [data-bs-target]{
  background-color: var(--clr-primary-100) !important;
  width: .75rem !important;
  height: .75rem !important;
  border-radius: 50%;
}

.carousel-control-next-icon, .carousel-control-prev-icon{
  background-color: var(--clr-primary-100);
  border-radius: .5rem;
}

/* .home-hero-section .carousel-control-next,
.home-hero-section .carousel-control-prev{
  display: none;
} */
/* end bootstrap */
.link {
  text-decoration: none;
}

.list {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}

.primary-heading {
  font-size: clamp(1rem, 3vw, 2rem);
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}
.heading-description{
  font-size: clamp(.875rem, 3vw, 1.25rem);
  max-width: 75ch;
}
.accent-heading {
  text-transform: uppercase;
  font-size: 1rem;
  color: var(--clr-accent-100);
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.text-primary {
  color: var(--clr-primary-200) !important;
}

.grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}

.grid-col-auto {
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}
