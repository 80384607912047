.topBanner {
  background-color: var(--clr-primary-200);
  width: 100%;
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding-block: 0.5rem;
}

.contactList,
.socialList {
  list-style: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 0;
  padding-left: 0;
}

.socialItem {
  /* display: flex;
  align-items: center;
  justify-content: center; */
  width: 2.5rem;
  height: 2.5rem;
}

.socialLink {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--clr-neutral-000);
  padding: 0.5rem;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  background-color: transparent;
  color: var(--clr-neutral-000);
  transition: all 250ms ease-in;
}

.socialLink:hover,
.socialLink:active {
  background-color: var(--clr-neutral-000);
  color: var(--clr-primary-200);
}

.contactList {
  flex-wrap: wrap;
  justify-content: end;
}
.contactLink {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  border-right: 1px solid var(--clr-neutral-000);
  padding: 0.5rem;
  color: var(--clr-neutral-000);
  position: relative;
  isolation: isolate;
  transition: all 250ms ease-in;
  white-space: nowrap;
}

.contactLink::after {
  content: "";
  width: 96%;
  height: 2px;
  background-color: var(--clr-neutral-000);
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.contactItem > div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  color: var(--clr-neutral-000);
  white-space: nowrap;
}
.contactLink:hover.contactLink::after {
  display: block;
}

@media (max-width: 768px) {
  .contactList,
  .content {
    flex-direction: column;
  }

  .contactLink {
    border-right: 0;
  }
}
