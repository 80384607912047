.heroSection {
  position: relative;
  width: 100%;
  min-height: calc(100vh - 115px);
  background-repeat: no-repeat;
  background-size: cover;
}

.bannerCarouselImage{
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  inset: 0;
}

.heroContainer {
  min-height: calc(100vh - 112px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.heroContent {
  padding: 1rem;
  background-color: #000;
  opacity: 0.7;
  color: #fff;
  border-radius: 0.5rem;
}

.backgroundImage {
  width: 100%;
  display: block;
  inset: 0;
  z-index: -1;
  object-fit: cover;
  /* position: fixed; */
}

.section {
  padding-block: 3rem;
  background-color: #fff;
  max-width: 100%;
}

.imageContainer {
  position: relative;
  isolation: isolate;
  width: 100%;
  min-height: 460px;

}
.decorImages {
  position: absolute;
  width: 325px;
  display: block;
  border-radius: 1rem;
}

.decorImages1 {
  top: 0px;
  right: 0px;
  z-index: -2;
  transition: all 250ms ease-in;
}
.decorImages2 {
  top: 135px;
  right: 20%;
  left: 20%;
  z-index: -1;
  transition: all 250ms ease-in;
}
.decorImages3 {
  top: 240px;
  left: 20px;
  z-index: 0;
  transition: all 250ms ease-in;
}

.decorImages1:hover,
.decorImages2:hover,
.decorImages3:hover{
  z-index: 2;
}
/* .section > * {
  width: 100%;
} */

.btnContainer {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  height: 100%;
}

@media (max-width: 991px) {
  .imageContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  }
  .decorImages {
    position: relative;
    width: 100%;
  }
  .decorImages1 {
    top: 0;
    left: 0;
  }
  .decorImages2 {
    top: 0px;
    left: 0px;
    z-index: 0;
  }
  .decorImages3 {
    top: 0;
    left: 0;
    z-index: -2;
  }
  .btnContainer {
    justify-content: center;
  }
}
