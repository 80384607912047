.card {
  border-radius: 1rem;
  position: relative;
  isolation: isolate;
  transition: all 250ms ease-in;
  overflow: hidden;
  width: 100%;
  height: 320px;
  cursor: pointer;
}

.card:focus-within,
.card:focus-visible,
.card:focus,
.card:hover {
  transform: translateY(-0.5rem);
  box-shadow: var(--box-shadow-heavy);
  -webkit-box-shadow: var(--box-shadow-heavy);
  -moz-box-shadow: var(--box-shadow-heavy);
}

.card::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  /* background: linear-gradient(to bottom, #fff 0%, #000 100%); */
  transition: opacity 0.35s ease-in-out;
  display: none;
  z-index: 2;
}

.card:focus-within.card::before,
.card:focus-visible.card::before,
.card:focus.card::before,
.card:hover.card::before {
  display: block;
}

.cardImage {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  z-index: 1;
}

.cardContent {
  position: absolute;
  bottom: 1.5rem;
  left: 0;
  right: 0;
  z-index: 3;
  text-align: center;
  /* display: none; */
  opacity: 0;
  transition: all 250ms ease-in;
}

.card:focus-within .cardContent,
.card:focus-visible .cardContent,
.card:focus .cardContent,
.card:hover .cardContent {
  /* display: block; */
  opacity: 1;
}

.cardTitle {
  color: var(--clr-neutral-000);
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  margin-bottom: 1rem;
}
.cardAction {
  cursor: pointer;
  padding: 0.75rem 1.5rem;
  border-radius: 100vh;
  margin-top: 1rem;
}

.cardAction:focus-within,
.cardAction:focus-visible,
.cardAction:focus,
.cardAction:hover {
  background-color: var(--clr-primary-200);
  color: var(--clr-neutral-000);
}
