.header {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1;
  background-color: #fff;
  box-shadow: -3px 3px 16px -3px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: -3px 3px 16px -3px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: -3px 3px 16px -3px rgba(0, 0, 0, 0.2);
}

.navbarBrand{
  width: 100%;
  max-width: 200px;
}

.logoImage{
  width: 100%;
  max-width: 200px;
  height: 50%;
  display: block;
}

.logoImageIcon{
  width: 50px;
  height: 50px;
}